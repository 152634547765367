import "styles/index.scss";

import close from "assets/icons/times-solid.svg";
import bandera from "assets/images/bandera-union-europea-s.jpg";
import logo from "assets/images/logo-jornadas-small.png";
import { Container } from "components/ui/objects/container";
import { Link } from "gatsby";
import React from "react";

import { Footer } from "../footer";
import { Details, Europe } from "../footer/styles";

const LayoutStep = ({ children }) => {
  // const theme = { primaryColor: '#00E787' };
  // const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!styles/settings/variables_jsx.scss');
  // console.log( 'theme ' + JSON.stringify(theme));
  return (
    <>
      <div className="full-screen">
        <div className="container modal">
          <Link to="/training-days/">
            <img src={logo} alt="Inserta XXI" width="63" height="63" />
          </Link>
          <Link to="/training-days/">
            <img src={close} alt="Salir" width="24" height="24" />
          </Link>
        </div>
        <div className={"full-screen--top"}>{children}</div>
        <Europe as={Container}>
          <img src={bandera} alt={"bandera Unión Europe"} width={"60"} />
          <Details style={{ fontSize: "0.85rem", opacity: "0.9" }}>
            El proyecto Inserta XXI está cofinanciado por el programa <span className={"uppercase"}>E</span>rasmus
            <sup>+</sup> de la Unión Europea. El contenido esta web es responsabilidad exclusiva del IES Ramón y Cajal
            de Zaragoza y ni la Comisión Europea, ni el Servicio Español para la Internacionalización de la Educación
            (SEPIE) son responsables del uso que pueda hacerse de la información aquí difundida.
          </Details>
        </Europe>
        <Footer />
      </div>
    </>
  );
};

export default LayoutStep;
