import React, { createRef, useState } from "react";

const LazyIframe = ({ src, title }) => {
  const [showVideo, setShowVideo] = useState(false);
  const container = createRef();

  const finishLoading = () => {
    // console.log('show')
    setShowVideo(true);
  };

  return (
    <div className="embed-iframe" ref={container}>
      <iframe
        className={showVideo ? "visibility" : "noVisibility"}
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${src}?controls=1&modestbranding=1&rel=0&showinfo=0&cc_load_policy=1`}
        title={title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        scrolling="no"
        onLoad={finishLoading}
        allowFullScreen
      ></iframe>
      <div className={showVideo ? "noVisibility" : "visibility loading"}>Loading ...</div>
    </div>
  );
};

export default LazyIframe;
